import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"

const carouselImg = "../../../images/slider.png";
const iconImg = "../../../images/destinations.png";


function Hero() {
	return (
        
        <div class="main-slideshow">
            
            <div className="mobile-hero">
         
<div class="elfsight-app-fdec7a79-fe23-4c6f-9ea2-e93e63d62f21"></div>
            </div>
            <div className="desktop-main">
         <div id="video-bg">  
     <iframe frameborder="0" 
            src="https://youtube.com/embed/Zd-lQcdoVn4?version=3&loop=1&playlist=Zd-lQcdoVn4&end=54&autoplay=1&mute=1&controls=0&showinfo=0" allowfullscreen>
    </iframe> 
        </div> 
            <div class="main-overlay"></div>
            <div class="hero-text">

                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                   
                
                    
                    <div class="carousel-inner">
                        <div class="item active">
                        <StaticImage src={carouselImg} quality={60} formats={["auto", "webp", "avif"]} />
                            <div class="carousel-caption">
                                
                              
                                <h1>Your Next Adventure is Waiting for You</h1>
                                <p>Featuring many stunning tours packed with excitment and unforgettable memories! D-Golden Tours specializes in snorkeling, fishing, bio-luminescent tours, natural formations, historical and heritage attractions, and animal preserves.
                                </p>
                                <a href="/all-tours" title="Tours in Belize"><button id="primary-button" type="button">View Tours</button></a>
                            </div>
                        </div>
                
                
                    </div>
                
                  
                    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                        <span class="glyphicon glyphicon-chevron-left"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#myCarousel" data-slide="next">
                        <span class="glyphicon glyphicon-chevron-right"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            </div>

            </div>

    );
}

export default Hero;